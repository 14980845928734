<template>
  <div class="register-wrap">
    <a-card class="light-shadow" :bordered="false">
      <template #title>
        <router-link to="/">
          <a-avatar
            shape="square"
            size="small"
            src="/images/logo.png"
            class="mr-2"
          ></a-avatar>
        </router-link>
        <span>{{ $t("account.create_account") }}</span>
      </template>
      <template #extra>
        <div class="langs-chooser">
          <LangChooser></LangChooser>
        </div>
      </template>
      <div class="register-form-wrap">
        <div class="register-form">
          <div class="text-center pb-4">
            <!-- <img src="/images/logo.png" style="height: 60px" /> -->
            <img
              v-if="isZhCn"
              src="@/assets/image/public/logo-4.png"
              style="height: 36px"
            />
            <img
              v-else
              src="@/assets/image/public/logo-3.png"
              style="height: 36px"
            />
          </div>
          <a-form
            ref="formRef"
            :model="formData"
            :label-col="{ span: 6 }"
            :rules="rules"
          >
            <a-form-item
              :label="$t('account.registration_country')"
              name="countryId"
            >
              <a-select
                v-model:value="formData.countryId"
                :placeholder="$t('account.registration_country')"
                :loading="formData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
              >
                <a-select-option
                  v-for="item in formData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.cnName + item.enName+item.ioS2"
                  >{{ getLanguageName(item) }}({{item.ioS2}})</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('account.phone_number')" name="phoneNumber">
              <a-input v-model:value="formData.phoneNumber">
                <template #prefix>
                  <span v-if="telCode">+{{ telCode }}</span>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item :label="$t('common.password')" name="password">
              <a-input-password
                v-model:value="formData.password"
              ></a-input-password>
            </a-form-item>
            <a-form-item
              :label="$t('account.confirm_password')"
              name="confirmPwd"
            >
              <a-input-password
                v-model:value="formData.confirmPwd"
              ></a-input-password>
            </a-form-item>
            <a-form-item
              :label="$t('account.validation_code')"
              name="captchaCode"
            >
              <a-input v-model:value="formData.captchaCode">
                <template #prefix>
                  <div class="validation-wrap">
                    <c-image
                      @click="refreshCapthImg"
                      v-if="formData.captchaImg"
                      class="validation-img"
                      :src="formData.captchaImg"
                      :preview="false"
                    ></c-image>
                  </div>
                </template>
                <template #suffix>
                  <a-button
                    :size="'small'"
                    type="link"
                    :loading="formData.smsSending"
                    :disabled="!formData.captchaCode || !!formData.timerSecond"
                    @click="sendSms"
                  >
                    {{ $t("account.send_sms") }}
                    <span v-if="formData.timerSecond"
                      >({{ formData.timerSecond }})</span
                    >
                  </a-button>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              v-if="!!formData.captchaCode"
              :label="$t('account.sms_validation_code')"
              name="validCode"
            >
              <a-input v-model:value="formData.validCode"></a-input>
            </a-form-item>
            <a-row type="flex" justify="center">
              <a-button
                type="primary"
                @click="handleRegister"
                :loading="formData.saving"
                >{{ $t("account.create_account") }}</a-button
              >
            </a-row>
          </a-form>
        </div>
      </div>
      <div style="text-align: right">
        <router-link to="/login">{{
          $t("account.login_account")
        }}</router-link>
      </div>
    </a-card>
        <CEnvTips />
  </div>
</template>
<script>
import {
  Avatar,
  Form,
  Input,
  Row,
  Col,
  Button,
  Card,
  Switch,
  Dropdown,
  Menu,
  Select,
  Space,
  Spin,
  message,
} from "ant-design-vue";
import { reactive, onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
import CImage from "../components/CImage.vue";
import LangChooser from "../components/LangChooser.vue";
import {
  genCaptchaInfo,
  getCaptchaUrl,
} from "../../api/modules/captcha/index.js";
import { sendRegisterSmsCode } from "../../api/modules/validCode/index.js";
import { registerAccount } from "../../api/modules/account/index.js";
import { getCountrys, getTimezones } from "../../api/modules/common/index.js";
import { useStore } from "vuex";
import * as i18nLocal from "@/locale/index.js";
import { getName, verifyMobilePhoneNumber } from "../../utils/general";
import { useI18n } from "vue-i18n/index";
import { tryGetReferInfo } from "@/hooks/refer/index";
import CEnvTips from '@/views/components/CEnvTips.vue'
export default {
  name: "register",
  components: {
    AAvatar: Avatar,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputPassword: Input.Password,
    AInputGroup: Input.Group,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ACard: Card,
    ASwitch: Switch,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpace: Space,
    ASpin: Spin,
    CImage,
    CEnvTips,
    LangChooser,
  },
  setup() {
    const vueI18n = useI18n();
    const store = useStore();
    const formRef = ref();
    const router = useRouter();
    const formData = reactive({
      countryId: null,
      phoneNumber: null,
      validCode: null,
      password: null,
      confirmPwd: null,
      saving: false,

      captchaRequired: false,
      captchaId: null,
      captchaCode: null,
      captchaImg: null,
      timerSecond: null,
      smsSending: false,

      countries: [],
      timeZones: [],
    });

    const telCode = computed(() => {
      if (formData.countryId && formData.countries.length > 0) {
        let item = formData.countries.find((x) => x.id == formData.countryId);
        return (item || {}).telCode;
      }
      return null;
    });

    const loadTimeZones = () => {
      getTimezones({}).then((res) => {
        formData.timeZones = res.result || [];
      });
    };

    const loadCountries = () => {
      getCountrys().then((res) => {
        //formData.countries = res.result;
        // 暂仅支持中国
        formData.countries = res.result.filter((x) => x.ioS2 == "CN" || x.ioS2=="US");

        if (
          formData.countryId &&
          formData.countries.findIndex((x) => x.id == formData.countryId) >= 0
        ) {
          //keep it
        } else {
          let selectedCountry = formData.countries[0];
          if (selectedCountry) {
            formData.countryId = selectedCountry.id;
          } else {
            formData.countryId = null;
          }
        }
      });
    };

    const getSelectedCountry = ()=>{
      if(formData.countryId){
        var country = formData.countries?.find((x) => x.id == formData.countryId);
        return country;
      }
      return null;
    }

    const loadCaptchaInfo = () => {
      genCaptchaInfo().then(({ result }) => {
        formData.captchaId = result.guid;
        refreshCapthImg();
      });
    };

    const refreshCapthImg = () => {
      if (formData.captchaId) {
        formData.captchaImg = getCaptchaUrl(formData.captchaId);
      }
      formData.captchaCode = null;
    };

    let timer = null;
    const startTimer = () => {
      if (timer) {
        clearInterval(timer);
      }
      formData.timerSecond = 120;
      timer = setInterval(() => {
        formData.timerSecond = formData.timerSecond - 1;
        if (formData.timerSecond < 1) {
          clearInterval(timer);
          formData.timerSecond = null;
        }
      }, 1000);
    };
    const sendSms = () => {
      formData.captchaRequired = true;
      //formData.captchaCode = null;
      formRef.value
        .validate(["phoneNumber", "captchaCode"])
        .then(() => {
          formData.smsSending = true;
          sendRegisterSmsCode({
            phoneNumber: formData.phoneNumber,
            guid: formData.captchaId,
            captchaCode: formData.captchaCode,
            countryCode: telCode.value,
          })
            .then(() => {
              startTimer();
            })
            .catch(() => {})
            .finally(() => {
              refreshCapthImg();
              formData.smsSending = false;
            });
        })
        .finally(() => {
          formData.captchaRequired = false;
        });
    };

    const handleRegister = () => {
      formRef.value
        .validate()
        .then(() => {
          let timezone = formData.timeZones.find(
            (x) => x.countryId == formData.countryId
          );
          formData.saving = true;
          var referInfo = tryGetReferInfo() || {};
          registerAccount({
            phoneNumber: formData.phoneNumber,
            passWord: formData.password,
            validCode: formData.validCode,
            countryId: formData.countryId,
            phoneCountryCode: telCode.value,
            timezone: (timezone || {}).zoneName,
            pt: referInfo.pt,
            tag: referInfo.tag,
          })
            .then((res) => {
              message.success(
                vueI18n.t("account.account_registration_successful")
              );
              formData.saving = false;
              router.push("/login");
            })
            .catch(() => {
              formData.saving = false;
            });
        })
        .catch(() => {});
    };

    const getLanguageName = (item) => {
      return getName(item, store.getters.language);
    };

    const isZhCn = computed(() => {
      return "zh-cn" == (store.getters.language || "").toLowerCase();
    });

    var chianMainlandPhoneReg = new RegExp("^1[3456789]\\d{9}$");
    const rules = {
      phoneNumber: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.phone_number"),
            ]);
          },
          trigger: ["change", "blur"],
        },
        {
          validator: (rule, value) => {
            if (value) {
              var selectedCountry = getSelectedCountry();
              if(selectedCountry){
                var phone = selectedCountry.telCode+value;
                // console.log(selectedCountry);
                if(verifyMobilePhoneNumber(selectedCountry.ioS2, phone)){
                  return Promise.resolve();
                }
              }
              // if (chianMainlandPhoneReg.test(value)) {
              //   return Promise.resolve();
              // }
              return Promise.reject();
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("account.phont_number_is_in_the_wrong_format");
            // return (
            //   vueI18n.t("account.phont_number_is_in_the_wrong_format") +
            //   "(" +
            //   vueI18n.t("account.currently_only_supports_mainland_china") +
            //   ")"
            // );
          },
          trigger: ["blur"],
        },
      ],
      countryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.registration_country"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
      password: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("common.password"),
            ]);
          },
          trigger: ["change", "blur"],
        },
        {
          min: 6,
          message: () => {
            return vueI18n.t("common.p0_must_be_at_least_p1_characters", [
              vueI18n.t("common.password"),
              6,
            ]);
          },
          trigger: "blur",
        },
      ],
      confirmPwd: [
        {
          required: true,
          validator: async (rule, value) => {
            if (formData.password == value) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
          message: () => {
            return vueI18n.t(
              "account.the_passwords_entered_twice_do_not_match"
            );
          },
          trigger: ["change", "blur"],
        },
      ],

      captchaCode: [
        {
          validator: async (rule, value) => {
            if (formData.captchaRequired) {
              if (value && value.length > 0) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.validation_code"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
      validCode: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("account.sms_validation_code"),
            ]);
          },
          trigger: ["change", "blur"],
        },
      ],
    };
    onMounted(() => {
      loadTimeZones();
      loadCountries();
      loadCaptchaInfo();
    });
    return {
      formData,
      formRef,
      telCode,
      isZhCn,

      refreshCapthImg,
      getLanguageName,
      sendSms,
      handleRegister,

      rules,
    };
  },
};
</script>
<style lang="less">
.register-wrap {
  width: 600px;
  max-width: 100%;
}
.register-form-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .register-form {
    width: 400px;
    max-width: 100%;
  }
}
.validation-wrap {
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: -10px;
  .validation-img {
    height: 30px;
    max-width: 100%;
    width: auto;
    border-radius: 4px 0 0 4px;
  }
}
</style>
