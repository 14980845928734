import client from "../../client";

const Api = {
    //发送注册邮件验证码
    registerValidCode: "/api/ProductService/ValidCode/RegisterValidCode",

    //发送注册短信验证码
    registerPhoneCode: "/api/ProductService/ValidCode/RegisterPhoneCode",
};

//发送注册邮件验证码
export const sendRegisterEmailCode = ({ email, culture }) => {
    return client.request({
        url: Api.registerValidCode,
        params: { email, culture },
        method: "GET",
    });
}

//发送注册短信验证码(countryCode为国际区号,如 86)
export const sendRegisterSmsCode = ({ phoneNumber, guid, captchaCode, countryCode }) => {
    return client.request({
        url: Api.registerPhoneCode,
        data: { phoneNumber, guid, captchaCode, countryCode },
        method: "POST",
    });
}